import React, { useState } from "react";
import { useRecoilValue } from "recoil";
import { useNavigate } from "@reach/router";
import { onboardingState, userState } from "../../atoms";
import { BodyOne, HeadingOne } from "../../components/typography";
import BlueButton from "../../components/buttons/BlueButton";
import AuthForm from "../../components/forms/AuthForm";
import { CheckBox } from "../../components/inputs";
import { countries } from "../../constants";
import { Success, Error } from "../../components/feedback";
import * as baseApi from "../../services/baseApi";
import { addOrgUserType } from "../../services/organisations";

const RoutesForm = (props) => {
  const onboardingData = useRecoilValue(onboardingState);
  const userData = useRecoilValue(userState);
  const [isLoading, setIsLoading] = useState(false);
  const [formRoutes, setFormRoutes] = useState([]);
  
  const navigate = useNavigate();

  const addRoutes = (e) => {
    const r = [...formRoutes];
    if (r.includes(e)) {
      const filtered = r.filter((t) => t !== e);
      setFormRoutes(filtered);
    } else {
      r.push(e);
      setFormRoutes(r);
    }
  };

  const onNext = async () => {
    try {
      if (formRoutes.length === 0) {
        Error({
          message: "Opps, thats not good",
          description: "Please select at least one route",
        });
      } else {
        setIsLoading(true);

        const surveyData = {
          fleet: { ...onboardingData.surveyData.fleet },
          routes: formRoutes,
          org_id: userData.org_id,
        };
        let sessionData = { ...userData };
        delete sessionData.password;
        const payloadObject = {
          fleetLength: {
            trucks: "0",
            trailers: "0",
            drivers: "0",
          },
          surveyData: surveyData,
          user_type: userData.userType,
          ...sessionData,
        };
        await addOrgUserType(payloadObject);
        await baseApi.post({
          url: "my-company/addSurveyData",
          payload: surveyData,
        });

        setIsLoading(false);
        navigate(`/signup-rediect/${userData.accessToken}`);
        Success({
          message: "Success, you have created an account! 🎉",
        });
      }
    } catch (e) {
      setIsLoading(false);
      Error({
        message: "Something went wrong",
        description: e.message,
      });
    }
  };

  return (
    <div className=" flex justify-center ">
      <div className="grid justify-items-center">
        <div>
          <HeadingOne className="text-tripplo-200 font-semibold text-center mt-4 mb-4">
            Type of organistation
          </HeadingOne>
          <AuthForm height="980px" active="Signup">
            <BodyOne className="text-base text-center">
              Please select the coutries you're interested in
            </BodyOne>
            {countries.map((country, index) => (
              <div className="flex justify-between">
                <BodyOne className="text-base text-center mb-8">
                  {country.country}{" "}
                </BodyOne>
                <CheckBox
                  value={country.country}
                  onClick={() => addRoutes(country.country)}
                />
              </div>
            ))}
          </AuthForm>
        </div>
        <div className="flex justify-between  mt-10">
          <BlueButton
            className=""
            onClick={() => props.setActiveKey("TrailersForm")}
            // isLoading={loading}
          >
            Previous
          </BlueButton>

          <BlueButton
            className=""
            onClick={() => onNext()}
            isLoading={isLoading}
          >
            Next
          </BlueButton>
        </div>
      </div>
    </div>
  );
};

export default RoutesForm;
