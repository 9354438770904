// Package Imports
import axios from "axios";

// Internal Imports
import { message } from "antd";
import { UserException } from "../utils/helpers";

// Internal Imports
import * as baseApi from "./baseApi";

export const addUserToGroup = async ({ groupName, email, sub }) => {
  const params = {
    groupname: groupName,
    username: sub,
    email: email,
  };

  return await baseApi.post({
    url: "cognito/users/addtogroup",
    payload: params,
  });
};

// FUNC - Add Org User-Type
export const addOrgUserType = async (payload) => {
  const { data } = await axios({
    url: `${process.env.REACT_APP_BASE_URL_V2}/organisations/my-company/addOrgUserType`,
    method: "POST",
    headers: {
      Accept: "*/*"
    },
    data: payload
  }).catch(err => {
    message.error("Could not add org user-type");
    throw UserException(err);
  });

  return data;
}
