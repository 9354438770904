import { useState } from "react";

// Convert string to base64
export const base64Encoder = (value) => {
    const input = Buffer.from(value, 'utf8');
    const output = input.toString('base64');
    return output
}

// Convert base64 to string
export const base64Decoder = (value) => {
    const input = Buffer.from(value, 'base64');
    const output = input.toString('utf8');
    return output;
}

// Object schema validation
export const compareObjects = (templateObject, comparisonObject) => {
    let templateObjectKeys = Object.keys(templateObject);
    templateObjectKeys.sort((a, b) => a > b);
    templateObjectKeys = JSON.stringify(templateObjectKeys);

    let comparisonObjectKeys = Object.keys(comparisonObject);
    comparisonObjectKeys.sort((a, b) => a > b);
    comparisonObjectKeys = JSON.stringify(comparisonObjectKeys);

    if (templateObjectKeys === comparisonObjectKeys) {
        return true;
    } else {
        return false;
    }
}

// Clean and Merge Objects
export const mergeObjects = (stateObjectKey, dataObject) => {
    const stateObject = JSON.parse(window.localStorage.getItem(stateObjectKey));
    return Object.assign(stateObject, dataObject);
}

// Store state locally
export const useLocalStorage = (key, initialValue) => {
    // State to store our value
    // Pass initial state function to useState so logic is only executed once
    const [storedValue, setStoredValue] = useState(() => {
        try {
            // Get from local storage by key
            const item = window.localStorage.getItem(key);
            // Parse stored json or if none return initialValue
            return item ? JSON.parse(item) : initialValue;
        } catch (error) {
            // If error also return initialValue
            console.log(error);
            return initialValue;
        }
    });
    // Return a wrapped version of useState's setter function that ...
    // ... persists the new value to localStorage.
    const setValue = (value) => {
        try {
            // Allow value to be a function so we have same API as useState
            const valueToStore =
                value instanceof Function ? value(storedValue) : value;
            // Save state
            setStoredValue(valueToStore);
            // Save to local storage
            window.localStorage.setItem(key, JSON.stringify(valueToStore));
        } catch (error) {
            // A more advanced implementation would handle the error case
            console.log(error);
        }
    };
    return [storedValue, setValue];
}

// Throw Custom Errors
export const UserException = (message) => {
    const throwObject = {
        name: "CustomException",
        message: message
    }

    return throwObject;
}

// Check for allowed UserTypes
export const allowedUserType = async (jwtToken) => {
    let processedJWT = jwtToken.split('.');
    processedJWT = base64Decoder(processedJWT[1]);
    processedJWT = JSON.parse(processedJWT);
    processedJWT = processedJWT["cognito:groups"][0];

    const allowedUserType = ['Transporter', 'TransBroker', 'Admin'];
    
    if (allowedUserType.includes(processedJWT)) return true;
    return false;
}

// Generate Current Date Epoch | Day:Month:Year:Hour:Minute
export const currentEpoch = () => {
  const currentDate = new Date().getTime() / 1000;
  const epoch = Math.floor(currentDate);
  return epoch;
};

// Generate timestamp in the following format: "2021-08-06T07:19:40.382104+00:00"
export const generateTimestamp = () => {
  const currentDate = new Date().toISOString();
  return currentDate;
}
