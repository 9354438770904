// Package Imports
import axios from "axios";
import { message } from "antd";

export const requestHandler = async ({ method, url_extension, params={}, token="", org_check=false }) => {
  const { data } = await axios({
    method: method,
    url: org_check ? `${process.env.REACT_APP_BASE_URL}/organisations/members/${url_extension}/organisations` : `${process.env.REACT_APP_BASE_URL}/${url_extension}`,
    data: JSON.stringify(params),
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      Accept: "*/*"
    }
  })
    .catch(e => {
      message.error(e.message);
    });

    return data;
}

export const _apiWrapper = async ({ url, method, payload, token="" }) => {
  return await axios({
    method: method,
    url: `${process.env.REACT_APP_BASE_URL}/${url}`,
    data: JSON.stringify(payload),
    headers: {
      Authorization: `Bearer ${ token} `,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
};

export const get = ({ url, token="", payload }) => {
  return _apiWrapper({
    url: url,
    method: "GET",
    payload: payload,
    token: token,
  });
};

export const post = async ({ url, token="", payload }) => {
  return await _apiWrapper({
    url: url,
    method: "POST",
    payload: payload,
    token: token,
  });
};

export const put = async ({ url, token="", payload }) => {
  return await _apiWrapper({
    url: url,
    method: "PUT",
    payload: payload,
    token: token
  });
};
