import React, { useState } from "react";
import ConfirmSignUpCode from "./ConfirmSignUpCode";
import SignUpForm from "./SignUpForm";
import CompanyForm from "./CompanyForm";
import OrganistationForm from "./OrganistationForm";
import FleetForm from "./FleetForm";
import TrucksForm from "./TrucksForm";
import DriversForm from "./DriversForm";
import TrailersForm from "./TrailersForm";
import RoutesForm from "./routesForm";

const SignUpIndex = (props) => {
  const [activeKey, setActiveKey] = useState("SignUpForm");
  return (
    <>
      {activeKey === "SignUpForm" && (
        <SignUpForm setActiveKey={setActiveKey} props={props} />
      )}

      {activeKey === "ConfirmSignUpCode" && (
        <ConfirmSignUpCode props={props} setActiveKey={setActiveKey} />
      )}

      {activeKey === "CompanyForm" && (
        <CompanyForm props={props} setActiveKey={setActiveKey} />
      )}

      {activeKey === "OrganistationForm" && (
        <OrganistationForm props={props} setActiveKey={setActiveKey} />
      )}

      {activeKey === "FleetForm" && (
        <FleetForm props={props} setActiveKey={setActiveKey} />
      )}

      {activeKey === "TrucksForm" && (
        <TrucksForm props={props} setActiveKey={setActiveKey} />
      )}
      
      {activeKey === "DriversForm" && (
        <DriversForm props={props} setActiveKey={setActiveKey} />
      )}

      {activeKey === "TrailersForm" && (
        <TrailersForm props={props} setActiveKey={setActiveKey} />
      )}

      {activeKey === "RoutesForm" && (
        <RoutesForm props={props} setActiveKey={setActiveKey} />
      )}
    </>
  );
};

export default SignUpIndex;
